import {
  DocxException,
  createEmptyLine,
  BOLD_TEXT,
  createKeyValueParagraph,
  createCombinationParagraphWithCenter,
} from '../../report/docxFunctions'

import {
  createReports,
  createShortReports,
} from '@/components/expConclusion/conclusionDocument/keiAvbReports'
import { KEI, AVB } from '@/components/expConclusion/const'

function createResInventoryPart(resInventory = []) {
  return [
    {
      type: BOLD_TEXT,
      content: ` (подаётся на включение в ${
        resInventory.length > 1 ? 'перечни' : 'перечень'
      } `,
    },
    resInventory?.length
      ? {
          type: BOLD_TEXT,
          content: resInventory.join(' и '),
        }
      : { type: BOLD_TEXT, color: 'D50000', content: '<не определено>' },
    { type: BOLD_TEXT, content: ')' },
  ]
}

export function createOneChapter(
  mnn,
  isGNVLP,
  isVZN14,
  company,
  condition,
  kei,
  avb
) {
  // подаётся на включение в перечень
  const resInventory = []
  if (isGNVLP) resInventory.push('ЖНВЛП')
  if (isVZN14) resInventory.push('14 ВЗН')

  try {
    return [
      createCombinationParagraphWithCenter([
        {
          type: BOLD_TEXT,
          content: mnn?.toLocaleUpperCase() ?? 'данные по мнн отсутствуют',
        },
        ...createResInventoryPart(resInventory),
      ]),
      createKeyValueParagraph('Компания-заявитель', company),
      createKeyValueParagraph(
        'Показание ЛП в соответствии с инструкцией',
        condition
      ),
      ...createShortReports(kei, KEI),
      ...createShortReports(avb, AVB),
      createEmptyLine(),
      ...createReports(kei, KEI),
      ...createReports(avb, AVB),
    ]
  } catch (err) {
    throw new DocxException(1, err.message)
  }
}
