<template>
  <v-card-actions class="text-right">
    <v-btn
      color="primary"
      :loading="loadingDocx"
      :disabled="disableUpload"
      @click="exportDocx"
      ><v-icon left> mdi-download </v-icon>
      Выгрузить сводное заключение (.docx)
    </v-btn>
  </v-card-actions>
</template>

<script>
import {
  Document,
  Packer,
  Paragraph,
  AlignmentType,
  PageOrientation,
  convertMillimetersToTwip,
  NumberFormat,
  TextRun,
  PageNumber,
  Footer,
} from 'docx'
import { saveAs } from 'file-saver'
import { createOneChapter } from '@/components/expConclusion/conclusionDocument/oneChapter'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { RED_LINE_INDENT } from '../report/docxFunctions'

export default {
  name: 'UploadConclusionReport',
  data: () => ({
    loadingDocx: false,
  }),
  props: {
    data: {
      type: Object,
    },
    disableUpload: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['storedPlpId']),
  },
  methods: {
    ...mapActions(['CREATE_ACTION_LOG']),
    ...mapMutations(['SET_ERROR']),
    exportDocx() {
      this.loadingDocx = true
      let oldFirstLine = RED_LINE_INDENT.indent.firstLine
      try {
        // по дефолту красную строку на 1,25см
        RED_LINE_INDENT.indent.firstLine = convertMillimetersToTwip(12.5)

        const doc = new Document({
          styles: {
            default: {
              document: {
                paragraph: {
                  spacing: {
                    // межстрочный интервал 1.5 ???
                    line: 360,
                  },
                },
              },
            },
          },
          sections: [
            {
              //Задаём нумерацию страниц
              footers: {
                default: new Footer({
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.RIGHT,
                      children: [
                        new TextRun({
                          children: [PageNumber.CURRENT],
                        }),
                      ],
                    }),
                  ],
                }),
              },
              properties: {
                page: {
                  size: {
                    //Задаём альбомную ориентацию документа, размеры листа A4
                    orientation: PageOrientation.PORTRAIT,
                    height: convertMillimetersToTwip(297),
                    width: convertMillimetersToTwip(210),
                  },
                  //отступы страницы, Величина измерения-пункты. Требуется подогнать ее под сантиметры
                  margin: {
                    top: convertMillimetersToTwip(15), //1 сантимерта
                    right: convertMillimetersToTwip(15), //1 сантиметра
                    bottom: convertMillimetersToTwip(15), //1 сантиметра
                    left: convertMillimetersToTwip(25), //1 сантиметра
                  },
                  pageNumbers: {
                    formatType: NumberFormat.DECIMAL,
                  },
                },
              },
              // весь документ
              children: [
                ...createOneChapter(
                  this.data.mnn,
                  this.data.gnvlp,
                  this.data.vzn14,
                  this.data.company,
                  this.data.condition,
                  this.data.kei,
                  this.data.avb
                ),
              ],
            },
          ],
        })
        Packer.toBlob(doc).then(blob => {
          //ЗАПИСЫВАЕМ В ЛОГ ВЫГРУЗКУ ОТЧЁТА
          this.CREATE_ACTION_LOG({
            code: 'CONCLUSION_REPORT_SAVE',
            message: `Сгенерировано сводное заключение для "${this.data.mnn}"`,
            data: {
              plp_id: this.storedPlpId,
            },
          })
          saveAs(
            blob,
            `Сводное заключение для ${
              this.data.mnn ?? ''
            } от ${this.getTimestamp()}`
          )
        })

        return doc
      } catch (err) {
        this.SET_ERROR({
          head: 'Заключение',
          text: 'Ошибка формирования документа',
          err,
        })
        throw err
      } finally {
        RED_LINE_INDENT.indent.firstLine = oldFirstLine
        this.loadingDocx = false
      }
    },
    getTimestamp() {
      const date = new Date()
      const addZero = v => (v.toString().length === 1 ? `0${v}` : v)
      const yyyy = date.getFullYear()
      const MM = addZero(date.getMonth() + 1)
      const dd = addZero(date.getDate())
      return `${dd}_${MM}_${yyyy}`
    },
  },
}
</script>
