<template>
  <v-form v-if="data" class="my-2" :readonly="!editMode">
    <v-card class="mb-3">
      <v-card-title>Расчет затрат</v-card-title>
      <v-card-subtitle v-if="editMode && needRefresh">
        <ReferenceTip>
          Обновить данные из экспертизы до актуальной версии можно кнопкой
          "обновить" ( <v-icon color="green" small>mdi-sync</v-icon> )
        </ReferenceTip>
      </v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col>
              <v-alert v-if="needRefresh" type="warning" dense text>
                Внимание! Информация по экспертизе изменилась, рекомендуется
                обновить данные
                <template #append>
                  <v-btn
                    v-if="editMode"
                    @click="refreshAbout"
                    :disabled="!needRefresh"
                    text
                    color="green"
                    small
                    title="Обновить все данные по экспертизе"
                    ><v-icon>mdi-sync</v-icon></v-btn
                  >
                </template>
              </v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-timeline dense align-top>
                <!-- МНН -->
                <v-timeline-item small>
                  <check-alert
                    v-model="data.mnn"
                    :check-value="initMnn"
                    :hide-check="logView"
                    @change="doChange(true)"
                    label="МНН"
                  />
                </v-timeline-item>
                <v-timeline-item small>
                  <check-alert
                    v-model="data.condition"
                    :check-value="initCondition"
                    :hide-check="logView"
                    @change="doChange(true)"
                    label="Показание"
                  />
                </v-timeline-item>
                <v-timeline-item small>
                  <check-alert
                    :value="defaultList"
                    :check-value="initList"
                    :hide-check="logView"
                    @change="listOfListChange"
                    empty-value="-- не определён --"
                    label="Перечень"
                  />
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- справочная информация -->
              <HelpCard open outlined>
                <v-expansion-panels class="my-2" flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="text-subtitle-2">
                      справка по работе с экспертизой” Расчёт затрат”.
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="text-body-2" eager>
                      <ul>
                        <li>
                          Если выбрано одно показание у ЖНВЛП или 14ВЗН, то
                          экспертиза будет искать листы с названиями-«Результаты
                          (ВЗН)” или “Результаты (ЖНВЛП)” в зависимости от
                          перечня.
                        </li>
                        <li>
                          Если же, например, выбрано несколько показаний у ЖНВЛП
                          и одно у ВЗН, то экспертиза будет искать листы с
                          названиями “Результаты (ЖНВЛП)-1”,” Результаты
                          (ЖНВЛП)-2”…,” Результаты (ЖНВЛП)-n” (n-количество
                          выбранных показаний у ЖНВЛП) и лист с названием
                          “Результаты (ВЗН)”.
                        </li>
                      </ul>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="text-subtitle-2">
                      справка по предупреждениям о показаниях
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="text-body-2" eager>
                      <ul>
                        <li>
                          Если в экспертизе выбрано несколько показаний для
                          перечня, а в документе присутствует только лист с
                          названием “Результаты (‘название перечня’)” без
                          нумераций, то появится предупреждение-“ В документе
                          содержится только один “название перечня”, укажите
                          корректное количество показаний “название перечня”.
                        </li>
                        <li>
                          Если в документе присутствует несколько листов
                          'Название перечня', а в системе в показаниях выбрано
                          один или меньше чем в документе, то в поле выбора
                          показаний появится предупреждение о том, что в
                          документе еще присутствуют листы ("В документе
                          присутствуют еще листы ('Название перечня'-n,'Название
                          перечня'-n+1...)").
                        </li>
                        <li>В листах не должно быть объединенных строк</li>
                        <li>
                          Под таблицей должно быть слово "Примечание" и т.д.
                        </li>
                      </ul>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </HelpCard>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" lg="6" v-if="data.gnvlp">
              <v-select
                v-model="data.countIndications.gnvlp"
                class="my-3 font-weight-bold"
                :items="scoresIndicators"
                :error="checkConditionCountGnvlp"
                :error-messages="gnvlpConditionMessage"
                @change="doChange(true)"
                hint="Сколько показаний у предлагаемого лекарственного препарата?"
                label="ЖНВЛП"
                persistent-hint
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" lg="6" v-if="data.vzn14">
              <v-select
                v-model="data.countIndications.vzn14"
                :items="scoresIndicators"
                :error="checkConditionCountVzn"
                :error-messages="vznConditionMessage"
                @change="doChange(true)"
                hint="Сколько показаний у предлагаемого лекарственного препарата?"
                label="14 ВЗН"
                persistent-hint
                class="my-3 font-weight-bold"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <UpdateCost
            :vzn="{
              count: data.countIndications.vzn14,
              isList: data.vzn14,
            }"
            :gnvlp="{
              count: data.countIndications.gnvlp,
              isList: data.gnvlp,
            }"
            :log-view="logView"
            :local-file="data.file"
            :edit-mode="editMode"
            :check-update-error="checkUpdateError"
            @change="doChangeFile"
            @blur="doBlur"
            @error="setUpdateError"
          />
          <v-row v-if="checkUpdateError" dense>
            <v-col>
              <v-alert type="error" outlined dense>
                {{ updateError }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row
            v-if="
              data.gnvlp &&
              data.indicationsData.gnvlp.length &&
              data.countIndications.gnvlp
            "
            dense
          >
            <v-col>
              <CostTabs
                :value="data.indicationsData.gnvlp"
                :calculations="data.indicationsData.gnvlpCalculations"
                :edit-mode="editMode"
                :mnn="data.mnn"
                :condition="data.condition"
                :count="data.countIndications.gnvlp"
                @change="doChangeGnvlpCalculating"
                @blur="doBlur"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="
              data.vzn14 &&
              data.indicationsData.vzn14.length &&
              data.countIndications.vzn14
            "
            dense
          >
            <v-col>
              <CostTabs
                vzn
                :value="data.indicationsData.vzn14"
                :calculations="data.indicationsData.vznCalculations"
                :edit-mode="editMode"
                :mnn="data.mnn"
                :condition="data.condition"
                :count="data.countIndications.vzn14"
                @change="doChangeVznCalculating"
                @blur="doBlur"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { copyObject } from '@/lib/objects'
import {
  DEFAULT_LOCAL_FILE,
  EX_COST_TEMPLATE,
} from '@/components/expCost/const'
import UpdateCost from '@/components/expCost/costExcel/UpdateCost'
import { SUB_EX_ABOUT } from '@/store/const/expertise'
import { mapActions } from 'vuex'
import CostTabs from '@/components/expCost/costTable/CostTabs'
import ReferenceTip from '@/components/ReferenceTip'
import CheckAlert from '@/components/CheckAlert'
import HelpCard from '@/components/HelpCard'

export default {
  name: 'CostCalc',
  components: {
    HelpCard,
    CheckAlert,
    ReferenceTip,
    CostTabs,
    UpdateCost,
  },
  data: () => ({
    data: null,
    loading: false,
    scoresIndicators: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ],
    initVzn14: false,
    initGnvlp: false,
    initMnn: '',
    initCondition: '',
    error: '',
    updateError: '',
    errorIdx: [],
  }),
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    logView: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: null,
    },
  },
  watch: {
    value(newElement) {
      if (this.readonly) this.data = this.value
      else this.data = copyObject(newElement)
    },
  },
  async created() {
    this.loading = true
    try {
      if (!this.logView) await this.loadAbout()

      if (this.readonly) this.data = this.value
      else this.data = copyObject(this.value)
      if (!this.data && this.onCreate) {
        console.log('need create expertise - cost')
        this.data = await this.onCreate(await this.initNewData())
      }
    } finally {
      this.loading = false
    }
  },
  async mounted() {
    this.reInit()
  },
  computed: {
    editMode() {
      return this.value && !this.readonly && !this.logView
    },
    needRefresh() {
      return !this.logView && !this.loading && this.showAlert
    },
    showAlert() {
      return !this.checkData
    },
    checkData() {
      return this.checkMnn && this.checkCondition && this.checkList
    },
    checkMnn() {
      return this.initMnn === this.data?.mnn
    },
    checkCondition() {
      return this.initCondition === this.data?.condition
    },
    checkConditionCountGnvlp() {
      return (
        this.data.countIndications.gnvlp <
        this.data.indicationsData.gnvlp.length
      )
    },
    checkConditionEmptyGnvlp() {
      return (
        this.data.countIndications.gnvlp > 0 &&
        this.data.indicationsData.gnvlp.length === 0
      )
    },
    checkConditionCountVzn() {
      return (
        this.data.countIndications.vzn14 <
        this.data.indicationsData.vzn14.length
      )
    },
    checkConditionEmptyVzn() {
      return (
        this.data.countIndications.vzn14 > 0 &&
        this.data.indicationsData.vzn14.length === 0
      )
    },
    gnvlpConditionMessage() {
      const indicationsCount = this.data.countIndications.gnvlp || 0
      const indicationsGnvlp = this.data.indicationsData.gnvlp || []

      if (this.checkConditionEmptyGnvlp)
        return 'В документе отсутствуют листы ЖНВЛП'
      if (this.checkConditionCountGnvlp) {
        const out = []
        for (let i = indicationsCount; i < indicationsGnvlp.length; i++) {
          out.push(indicationsGnvlp[i].name || i)
        }
        return `В документе присутствуют еще листы (${out.join(', ')}).`
      } else return ''
    },
    vznConditionMessage() {
      const indicationsCount = this.data.countIndications?.vzn14 || 0
      const indicationsVzn = this.data.indicationsData?.vzn14 || []

      if (this.checkConditionEmptyVzn)
        return 'В документе отсутствуют листы 14ВЗН'
      if (this.checkConditionCountVzn) {
        const out = []
        for (let i = indicationsCount; i < indicationsVzn.length; i++) {
          out.push(indicationsVzn[i].name || i)
        }
        return `В документе присутствуют еще листы (${out.join(', ')}).`
      } else return ''
    },
    checkList() {
      return this.defaultList === this.initList
    },
    checkUpdateError() {
      return (
        this.updateError &&
        !this.data.indicationsData?.gnvlp?.length &&
        !this.data.indicationsData?.vzn14?.length
      )
    },
    initList() {
      const list = []
      if (this.initGnvlp) list.push('ЖНВЛП')
      if (this.initVzn14) list.push('ВЗН14')
      return list.join(', ')
    },
    defaultList() {
      const list = []
      if (this.data?.gnvlp) list.push('ЖНВЛП')
      if (this.data?.vzn14) list.push('ВЗН14')
      return list.join(', ')
    },
  },
  methods: {
    ...mapActions(['GET_PLP_REPORT', 'GET_PLP_FILE']),
    doChange(force = false) {
      if (!this.readonly) this.$emit('change', this.data, force)
    },
    doChangeFile(data) {
      this.data.indicationsData.gnvlp = data.gnvlp || []
      this.data.indicationsData.gnvlpCalculations = this.getCalculatingCost(
        this.data.indicationsData.gnvlpCalculations,
        data.gnvlpCalculations
      )
      this.data.indicationsData.vzn14 = data.vzn || []
      this.data.indicationsData.vznCalculations = this.getCalculatingCost(
        this.data.indicationsData.vznCalculations,
        data.vznCalculations
      )

      this.data.file = data.localFile || this.data.file || DEFAULT_LOCAL_FILE()

      this.changeIndications()

      this.doChange(true)
    },
    changeIndications() {
      if (
        this.data.countIndications.gnvlp === 0 ||
        this.data.countIndications.gnvlp === null
      ) {
        this.data.countIndications.gnvlp =
          this.data?.indicationsData?.gnvlp?.length || 0
      }
      if (
        this.data.countIndications.vzn14 === 0 ||
        this.data.countIndications.vzn14 === null
      ) {
        this.data.countIndications.vzn14 =
          this.data?.indicationsData?.vzn14?.length || 0
      }
    },
    getCalculatingCost(defaultCalc = [], changedCalc = []) {
      if (changedCalc.length > defaultCalc.length) {
        for (let i = defaultCalc.length; i < changedCalc.length; i++) {
          defaultCalc.push(changedCalc[i])
        }
      }
      return defaultCalc
    },
    async refreshAbout() {
      this.data.mnn = this.initMnn || ''
      this.data.condition = this.initCondition || ''
      this.data.gnvlp = this.initGnvlp || false
      this.data.vzn14 = this.initVzn14 || false

      this.doChange(true)
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    reInit() {
      if (this.readonly) {
        this.data = this.value
      }
    },
    async initNewData() {
      try {
        const resData = await this.getExpertiseData()
        resData.mnn = this.initMnn || ''
        resData.condition = this.initCondition || ''
        resData.vzn14 = this.initVzn14 || false
        resData.gnvlp = this.initGnvlp || false

        return resData
      } catch (err) {
        this.SET_ERROR({
          head: 'Расчёт затрат',
          text: 'Ошибка загрузки данных',
          err,
        })
      }
    },
    async getExpertiseData() {
      try {
        const data = copyObject(EX_COST_TEMPLATE())

        return data
      } catch (err) {
        this.SET_ERROR({
          head: 'Расчёт затрат',
          text: 'Ошибка загрузки данных по Расчёту затрат',
          err,
        })
      }
    },
    async loadAbout() {
      this.loading = true
      try {
        const { expertise } = await this.GET_PLP_REPORT([SUB_EX_ABOUT])
        const about = expertise?.about || {}
        this.initGnvlp = about.gnvlp || false
        this.initVzn14 = about.vzn14 || false
        this.initMnn = about.mnn || ''
        this.initCondition = about.condition || ''
      } catch (err) {
        this.error = err.message + (err.error ? ' ' + err.error : '')
        throw err
      } finally {
        this.loading = false
      }
    },
    setUpdateError(updateError = 'Неккоректный файл под заданные параметры') {
      this.updateError = updateError
    },
    doChangeGnvlpCalculating(data, idx, force) {
      this.data.indicationsData.gnvlpCalculations[idx] = data
      this.doChange(force)
    },
    doChangeVznCalculating(data, idx, force) {
      this.data.indicationsData.vznCalculations[idx] = data
      this.doChange(force)
    },
    listOfListChange() {
      this.data.gnvlp = this.initGnvlp || false
      this.data.vzn14 = this.initVzn14 || false

      this.doChange(true)
    },
  },
}
</script>
