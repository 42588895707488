import {
  DocxException,
  createContentParagraph,
  createSecondContentParagraph,
  createEmptyLine,
} from '../docxFunctions'
import { createConsequenceTable } from '../allTables/createConsequenceTable'
import { createScoreTable } from '../allTables/createScoreTable'
import { createAgenciesTable } from '../allTables/createAgenciesTable'
import { createGnvlpTable } from '../allTables/createGnvlpTable'
import { createAssessmentTable } from '../allTables/createAssessmentTable'
import { createParagraphWithCenter } from '../docxFunctions'

export function createGnvlpTables(indications) {
  const allTables = []
  indications.forEach(element => {
    allTables.push(
      createParagraphWithCenter(
        `${element.valuesIndications.join(', ') || '-'}`,
        {
          size: 24,
          bold: true,
        },
        null
      )
    )
    allTables.push(createGnvlpTable(element), createEmptyLine())
  })
  return allTables
}
export function createVznTables(indications) {
  const allTables = []
  indications.forEach(element => {
    allTables.push(
      createParagraphWithCenter(
        `${element.valuesIndications.join(', ') || '-'}`,
        {
          size: 24,
          bold: true,
        },
        null
      )
    )
    allTables.push(createGnvlpTable(element), createEmptyLine())
  })
  return allTables
}
/**
 * Экономические последствия
 */
export function createTwoChapter(cost, assessment) {
  const allIndications = cost?.indicationsData || []
  const gnvlpIndications = allIndications?.gnvlp || []
  const vznIndications = allIndications?.vzn14 || []

  try {
    return [
      createEmptyLine(),
      createContentParagraph(
        '4. Результаты оценки экономических последствий применения лекарственного препарата:'
      ),
      createEmptyLine(),
      createSecondContentParagraph(
        '4.1. расчет стоимости курса (года) лечения предлагаемым лекарственным препаратом по сравнению с аналогичными лекарственными препаратами, включенными в перечни, указанные в подпункте «д» пункта 3 настоящего документа, и применяющимися по тем же показаниям, что и предлагаемый лекарственный препарат:'
      ),
      createEmptyLine(),
      ...createGnvlpTables(gnvlpIndications),
      ...createVznTables(vznIndications),
      createSecondContentParagraph(
        '4.2. результаты клинико-экономической оценки и оценки влияния на бюджеты бюджетной системы Российской Федерации (далее - бюджет) лекарственного препарата по каждому показанию с указанием кода Международной статистической классификации болезней и проблем, связанных со здоровьем, расшифровка:'
      ),
      createEmptyLine(),
      createAssessmentTable(assessment),
    ]
  } catch (err) {
    throw new DocxException(3, err.message)
  }
}
