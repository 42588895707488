import { copyObject, sameObject, copyObjectBy } from '@/lib/objects'
import { EX_CONCLUSION_KEI } from '@/components/expConclusion/const'

/***
 *Создание копии Массива КЭИ
 * @param expKei массив КЭИ с экспертизы сводного заключения
 * @param initKei массив КЭИ с экспретизы КЭИ
 * @returns []
 */
export function createKeiCopy(expKei, initKei, storedKeiCriteria) {
  if (!initKei) return copyObject(expKei)
  const kei = []
  initKei.ka.forEach(item => {
    kei.push(
      keiCopy(
        expKei?.find(d => d.shortName === item.shortName),
        item,
        storedKeiCriteria
      )
    )
  })
  return kei
}

/***
 *Проверка всей экспертизы КЭИ на актуальность данных
 * @param expKei массив КЭИ с экспертизы сводного заключения
 * @param initKei массив КЭИ с экспретизы КЭИ
 * @returns Boolean
 */
export function checkKei(expKei, initKei) {
  if (!checkKeiLength(expKei, initKei)) {
    return false
  }
  for (let i = 0; i < expKei.length; i++) {
    if (!checkCopy(expKei[i], initKei?.ka[i])) {
      return false
    }
  }
  return true
}

export function checkKeiLength(expKei, initKei) {
  return expKei?.length === initKei?.ka?.length
}

/***
 *Создание копии элемента КЭИ
 * @param kei элемент КЭИ с экспертизы сводного заключения
 * @param initKei Элемент КЭИ с экспретизы КЭИ
 * @returns {{}}
 */
export function keiCopy(kei, initKei, storedKeiCriteria) {
  const resKei = {
    ...copyObjectBy(kei, EX_CONCLUSION_KEI),
  }
  resKei.shortName = initKei.shortName
  resKei.qLevels = initKei.qLevels
  resKei.condition = initKei.condition
  // массив сценариев
  resKei.analogsCompare = copyObject(initKei.resume?.analog)
  // автор, год
  resKei.resume = initKei.resume?.author
  // копируем используемый метод кэи
  resKei.method = copyObject(initKei.resume?.method)
  // собиираем критерии и выставляем пункты несоответствия постановлению...
  resKei.criterias = createKeiCriterias(
    initKei.elements,
    storedKeiCriteria,
    resKei.criterias,
    resKei
  )
  // коментарий к экспертизе КЭИ
  resKei.comment = initKei.resume?.comment
  return resKei
}

/***
 *Проверка свойств элемента на актуальность данных
 * @param kei Элемент КЭИ с экспертизы сводного заключения
 * @param initKei Элемент КЭИ с экспретизы КЭИ
 * @returns Boolean
 */
function checkCopy(kei, initKei) {
  const resKei = keiCopy(null, initKei)

  // критерии
  if (kei.criterias?.length !== resKei.criterias?.length) return false

  for (let i = 0; i < kei.criterias?.length; i++) {
    const src = kei.criterias[i] || {}
    const res = resKei.criterias?.[i] || {}

    if (src.id !== res.id) return false
    if (
      !src.manual &&
      (!sameObject(src.description, res.description) ||
        !sameObject(src.comment, res.comment))
    )
      return false
  }

  for (const key of [
    'shortName',
    'qLevels',
    'resume',
    'condition',
    'analogsCompare',
    'method',
    'comment',
  ]) {
    if (!sameObject(resKei[key], kei[key])) return false
  }

  return true
}

function maskMethodBoolArray(array = [], mask = []) {
  if (!Array.isArray(array) || !Array.isArray(mask)) return false
  let result = false
  for (let i = 0; i < array.length; i++) {
    if (typeof mask[i] === 'boolean') {
      // если в маске false, а в массиве true вот тут плохо, масска не совпала уходим
      if (!mask[i] && array[i]) return false
      // а если в маске true и в массиве был true то маска совпала - запомним
      if (mask[i] && array[i]) result = true
    }
  }
  return result // если хотя бы одно из true в маске совпало с true в массиве то гут!
}

function checkAndGetRequirements(
  { items, conditions } = { items: [] },
  keiExp
) {
  // если нет правил отдаём просто элементы
  if (!conditions) return copyObject(items ?? [])
  // проверяем по правилам и получаем массив пунктов itemRequirements
  const result = []
  for (const el of items) {
    if (el in conditions) {
      // для нашего элемента есть правило.
      // пока что мы знаем что есть только метод (method)
      const { method } = conditions[el]
      // тут могут быть и другие проверки (futures)
      // ПРОВЕРКА method
      if (method && maskMethodBoolArray(keiExp.method.items, method)) {
        result.push(el) // Провеврка пройдена, есть соответствие маске
      }
    } else result.push(el)
  }

  return copyObject(result)
}

function createKeiCriterias(elements, storedKeiCriteria, criterias, keiExp) {
  const resRequirements = new Map()
  // Собираем пункты постановления для критериев и правила
  storedKeiCriteria?.forEach(({ id, data }) => {
    const { itemRequirements, itemRequirementsCondition } = data || {}
    const requirement = {
      items: itemRequirements ?? [],
      conditions: itemRequirementsCondition,
    }
    resRequirements.set(id, requirement)
  })
  // перебираем критерии и проверяем условия
  return elements
    .filter(({ correspond }) => !correspond)
    .map((item, idx) => ({
      id: item.id,
      name: item.name,
      order: item.order,
      itemRequirements: checkAndGetRequirements(
        resRequirements.get(item.id),
        keiExp
      ),
      description: criterias?.[idx]?.manual
        ? criterias?.[idx].description
        : item.criteria,
      comment: criterias?.[idx]?.manual
        ? criterias?.[idx].comment
        : item.comment,
      manual: criterias?.[idx]?.manual || false,
    }))
}
