import { AlignmentType, Table, TableCell, TableRow, WidthType } from 'docx'

import {
  createBoldParagraph,
  DEFAULT_TABLE_MARGINS,
  createTableCell,
  BOLD_TEXT,
} from '../docxFunctions'
import { EX_KEI_METHOD } from '@/components/expAvbKei/const'
import {
  createCombinationParagraph,
  DEFAULT_TEXT,
  UNDERLINED_TEXT,
} from '@/components/report/docxFunctions'

const params = new Map([
  ['qLevels', 'Метод. качество'],
  ['condition', 'Показание'],
  ['method', 'Использованный метод '],
  ['analogsCompare', 'Препараты сравнения'],
  ['clinicalAdvantages', 'Клинические преймущества'],
  ['econAdvantages', 'Экономические преймущества'],
  ['results', 'Основные результаты'],
])

export function getForKeiScenario(items = [], keiIdx) {
  const outArr = []

  items?.forEach((item, idx, arr) => {
    // если есть сценарии

    if (arr.length > 1) {
      outArr.push(
        createCombinationParagraph([
          {
            type: BOLD_TEXT,
            content: `${idx === 0 ? `КЭИ-${keiIdx + 1}: ` : ''}`,
          },
          {
            type: UNDERLINED_TEXT,
            content: `Сценарий ${idx + 1}:`,
          },
        ])
      )
    }

    outArr.push(
      createCombinationParagraph([
        {
          type: BOLD_TEXT,
          content: `${
            idx === 0 && arr.length === 1 ? `КЭИ-${keiIdx + 1}:` : ''
          }`,
        },
        {
          type: DEFAULT_TEXT,
          content: ` ${item || ''}`,
        },
      ])
    )
  })
  return outArr
}

function reduceKeiMethodList(keiItem) {
  const methods = []
  //по умолчанию аннотации пустые, т. е их нет в массиве аннотаций
  let annotationsEmpty = true
  const annotations = keiItem?.method?.annotations || []
  keiItem?.method?.items?.forEach((check, idx) => {
    if (check) {
      //если есть хотя бы одна аннотация
      if (annotations[idx]) annotationsEmpty = false
      const methodTitle = String(EX_KEI_METHOD[idx]?.title || '')

      // Если нет хотя бы одной аннотаций то не добавляем ее
      if (annotationsEmpty) {
        methods.push(`${methodTitle.toLocaleLowerCase()}`.toLowerCase())
      } else {
        const annotation = annotations[idx] || ''
        methods.push(
          `- ${methodTitle.toLocaleLowerCase()} ${annotation}`.trim()
        )
      }
    }
  })
  return methods.join(`${annotationsEmpty ? ', ' : '\n '}`)
}

function createCellsInRow(tableData, name) {
  const out = []
  if (name === 'qLevels' || name === 'analogsCompare') {
    out.push(
      createTableCell({
        children: [
          createBoldParagraph(
            params.get(name),
            { alignment: AlignmentType.START },
            22
          ),
        ],
        columnSpan: 1,
      })
    )
  }
  if (name === 'method') {
    tableData.forEach((item, idx) => {
      out.push(
        createTableCell({
          children: [
            createCombinationParagraph([
              {
                type: BOLD_TEXT,
                content: `КЭИ-${idx + 1}:`,
              },
              {
                type: DEFAULT_TEXT,
                content: reduceKeiMethodList(item),
              },
            ]),
          ],
          columnSpan: 2,
        })
      )
    })
  } else {
    tableData.forEach((item, idx) => {
      if (Array.isArray(item[name])) {
        if (name === 'qLevels' || name === 'analogsCompare') {
          out.push(
            createTableCell({
              children: [...getForKeiScenario(item[name], idx)],
              columnSpan: idx === 0 ? 1 : 2,
            })
          )
        } else {
          out.push(
            createTableCell({
              children: [...getForKeiScenario(item[name], idx)],
              columnSpan: 2,
            })
          )
        }
      } else {
        if (name === 'qLevels' || name === 'analogsCompare') {
          out.push(
            createTableCell({
              children: [
                createCombinationParagraph([
                  {
                    type: BOLD_TEXT,
                    content: `КЭИ-${idx + 1}:`,
                  },
                  {
                    type: DEFAULT_TEXT,
                    content: item[name],
                  },
                ]),
              ],
              columnSpan: idx === 0 ? 1 : 2,
            })
          )
        } else {
          out.push(
            createTableCell({
              children: [
                createCombinationParagraph([
                  {
                    type: BOLD_TEXT,
                    content: `КЭИ-${idx + 1}:`,
                  },
                  {
                    type: DEFAULT_TEXT,
                    content: item[name],
                  },
                ]),
              ],
              columnSpan: 2,
            })
          )
        }
      }
    })
  }
  return out
}

function createHeaderRow(headerName, dataLength) {
  return new TableRow({
    children: [
      new TableCell({
        children: [
          createBoldParagraph(
            headerName,
            { alignment: AlignmentType.CENTER },
            22
          ),
        ],
        columnSpan: dataLength * 2,
      }),
    ],
  })
}

////////////////////////////////////////////
export function createKeiTable(tableData) {
  const rows = []
  rows.push(
    new TableRow({
      children: [...createCellsInRow(tableData, 'qLevels')],
    }),
    createHeaderRow('Показание', tableData.length),
    new TableRow({
      children: [...createCellsInRow(tableData, 'condition')],
    }),
    createHeaderRow('Использованный метод', tableData.length),
    new TableRow({
      children: [...createCellsInRow(tableData, 'method')],
    }),
    new TableRow({
      children: [...createCellsInRow(tableData, 'analogsCompare')],
    }),
    createHeaderRow('Клинические преймущества', tableData.length),
    new TableRow({
      children: [...createCellsInRow(tableData, 'clinicalAdvantages')],
    }),
    createHeaderRow('Экономические преимущества', tableData.length),
    new TableRow({
      children: [...createCellsInRow(tableData, 'econAdvantages')],
    }),
    createHeaderRow('Основные результаты', tableData.length),
    new TableRow({
      children: [...createCellsInRow(tableData, 'results')],
    })
  )
  try {
    return new Table({
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [...rows],
    })
  } catch (err) {
    throw new Error(
      `[createKeiTable] Ошибка построения таблицы "Kei": ${err.message}`
    )
  }
}
