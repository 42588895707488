import { AlignmentType, Table, TableRow, WidthType } from 'docx'
import {
  createBoldParagraph,
  createParagraph,
  createSeparateParagraphs,
  createTableCell,
  DEFAULT_TABLE_MARGINS,
} from '../docxFunctions'

export function createCellsInRow(row) {
  try {
    return [
      createTableCell({
        children: [createParagraph(row.groupName.value ?? '-', {}, {}, 20)],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.mnn.value || '',
            { alignment: AlignmentType.CENTER },
            {},
            20
          ),
        ],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.patientPopulation.value || '-',
            { alignment: AlignmentType.CENTER },
            {},
            20
          ),
        ],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.EI.value || '',
            { alignment: AlignmentType.CENTER },
            {},
            20
          ),
        ],
      }),
      createTableCell({
        children: createSeparateParagraphs(
          row.requiredEiNumber.value || '0',
          { alignment: AlignmentType.CENTER },
          {},
          20
        ),
      }),
      createTableCell({
        children: [
          createParagraph(
            row.priceEiNumber.value || '0',
            { alignment: AlignmentType.CENTER },
            {},
            20
          ),
        ],
      }),
      createTableCell({
        children: [
          createParagraph(
            row.priceTherapy.value || '0',
            { alignment: AlignmentType.CENTER },
            {},
            20
          ),
        ],
      }),
    ]
  } catch (err) {
    throw new Error(`${row?.mnn.value || '<не определёно>'}: ${err}`)
  }
}

function createHeaderCellsInRow(headers) {
  const outHeaders = []
  headers.forEach(element => {
    outHeaders.push(
      createTableCell({
        children: [
          createBoldParagraph(element, { alignment: AlignmentType.CENTER }, 20),
        ],
      })
    )
  })
  return outHeaders
}

export function createTableHeaderRows(headers) {
  return [
    new TableRow({
      children: createHeaderCellsInRow(headers),
    }),
  ]
}

export function createGnvlpTable(tableData) {
  try {
    return new Table({
      alignment: AlignmentType.JUSTIFIED,
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        ...createTableHeaderRows(tableData.headers || []),
        ...tableData.tableData.map(
          row =>
            new TableRow({
              children: [...createCellsInRow(row)],
            })
        ),
      ],
    })
  } catch (err) {
    throw new Error(
      `[createGnvlpTable] Ошибка построения таблицы gnvlp: ${err.message}`
    )
  }
}
