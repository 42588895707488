import {
  createEmptyLine,
  createParagraph,
  createParagraphWithCenter,
  DEFAULT_TEXT,
  DocxException,
  UNDERLINED_BOLD_TEXT,
  BOLD_TEXT,
} from '../docxFunctions'
import { createCostTable } from '@/components/reportPresentation/allTables/createCostTable'
import {
  createCombinationParagraph,
  ITALIC_TEXT,
} from '@/components/report/docxFunctions'

export function createThreeChapter(data) {
  const tablesGnvlp = []
  const tablesVzn = []

  data.indicationsData?.gnvlp?.forEach((table, idx) => {
    if (idx + 1 > data.countIndications?.gnvlp) return

    const calcOffer = []
    const calcYear = ['Продолжительность терапии в год: ']
    const calcPrice = ['Стоимость ']
    const calcCombine = []

    const footers = []
    const costOnePatient = []
    const calculation = data.indicationsData?.gnvlpCalculations[idx] || {}

    calculation.costs?.forEach(cost => {
      calcOffer.push(
        createParagraph(
          `для препарата "${cost.offerLp || '-пусто-'}" ${
            cost.dosageMedicine || '-'
          }, `,
          {},
          {},
          20
        )
      )
      calcYear.push(
        `для препарата "${cost.offerLp || '-пусто-'}": ≈ ${
          cost.durationTherapy || '-'
        }. `
      )
      calcPrice.push(
        `1 мг препарата "${cost.offerLp || '-пусто-'}": ≈ ${
          cost.costOneMg || '-'
        }. `
      )
      calcCombine.push(
        createCombinationParagraph([
          {
            type: BOLD_TEXT,
            content: cost.offerLp ? cost.offerLp + ': ' : '-пусто-',
            size: 20,
          },
          {
            type: DEFAULT_TEXT,
            content: cost.costOnePatient[0].formula || '-',
            size: 20,
          },
        ])
      )
    })

    calculation.costs?.costOnePatient?.forEach((cost, idx) => {
      costOnePatient.push(
        createParagraph(
          `Расчет стоимости ${idx + 1} года терапии одного пациента: ${
            cost.formula || '-'
          }: `,
          {},
          {},
          20
        )
      )
    })
    table.valuesFooters.forEach(item => {
      footers.push(
        createCombinationParagraph([{ type: ITALIC_TEXT, content: item }])
      )
    })
    tablesGnvlp.push(
      createParagraphWithCenter(
        `${table.valuesIndications.join(', ') || '-'}`,
        {
          size: 28,
          bold: true,
        },
        null
      )
    )
    tablesGnvlp.push(createCostTable(table.tableData, table.headers))
    tablesGnvlp.push(...footers)
    tablesGnvlp.push(
      createEmptyLine(),
      createCombinationParagraph([
        { type: UNDERLINED_BOLD_TEXT, content: 'Расчёты:' },
      ]),
      createParagraph(
        `Режим дозирования препарата ${calculation.name || '-пусто-'} у ${
          calculation.indication || '-'
        } по инструкции (данные, использованны для расчетов) составляет: `,
        {},
        {},
        20
      ),
      createEmptyLine(),
      ...calcOffer,
      createParagraph(calcYear.join(''), {}, {}, 20),
      createParagraph(calcPrice.join(''), {}, {}, 20),
      createEmptyLine(),
      createParagraph(
        `Расчет стоимости 1 года терапии одного пациента: ${
          calculation.combinePrice || ''
        }`,
        {},
        {},
        20
      ),
      ...calcCombine
      // createParagraph(
      //   `Продолжительность терапии в год: ${
      //     calculation.durationTherapy || '-'
      //   }`,
      //   {},
      //   {},
      //   20
      // ),
      // createParagraph(
      //   `Стоимость 1 мг препарата ${data.mnn || '-пусто-'}: ${
      //     calculation.costOneMg || '-'
      //   }`,
      //   {},
      //   {},
      //   20
      // ),
      // ...costOnePatient
    )
    tablesGnvlp.push(createEmptyLine())
  })
  data.indicationsData?.vzn14?.forEach((table, idx) => {
    if (idx + 1 > data.countIndications?.vzn14) return

    const calcOffer = []
    const calcYear = ['Продолжительность терапии в год: ']
    const calcPrice = ['Стоимость ']
    const calcCombine = []

    const footers = []
    const costOnePatient = []
    const calculation = data.indicationsData?.vznCalculations[idx] || {}

    calculation.costs?.forEach(cost => {
      calcOffer.push(
        createParagraph(
          `для препарата ${cost.offerLp || '-пусто-'} ${
            cost.dosageMedicine || '-'
          }, `,
          {},
          {},
          20
        )
      )
      calcYear.push(
        `для препарата "${cost.offerLp || '-пусто-'}": ≈ ${
          cost.durationTherapy || '-'
        }. `
      )
      calcPrice.push(
        `1 мг препарата "${cost.offerLp || '-пусто-'}": ≈ ${
          cost.costOneMg || '-'
        }. `
      )
      calcCombine.push(
        createCombinationParagraph([
          {
            type: BOLD_TEXT,
            content: cost.offerLp ? cost.offerLp + ': ' : '-пусто-',
            size: 20,
          },
          {
            type: DEFAULT_TEXT,
            content: cost.costOnePatient[0].formula || '-',
            size: 20,
          },
        ])
      )
    })

    calculation.costs?.costOnePatient?.forEach((cost, idx) => {
      costOnePatient.push(
        createParagraph(
          `Расчет стоимости ${idx + 1} года терапии одного пациента: ${
            cost.formula || '-'
          }: `,
          {},
          {},
          20
        )
      )
    })
    table.valuesFooters.forEach(item => {
      footers.push(
        createCombinationParagraph([{ type: ITALIC_TEXT, content: item }])
      )
    })
    tablesVzn.push(
      createParagraphWithCenter(
        `${table.valuesIndications.join(', ') || '-'}`,
        {
          size: 28,
          bold: true,
        },
        null
      )
    )
    tablesVzn.push(createCostTable(table.tableData, table.headers))
    tablesVzn.push(...footers)
    tablesVzn.push(
      createEmptyLine(),
      createCombinationParagraph([
        { type: UNDERLINED_BOLD_TEXT, content: 'Расчёты:' },
      ]),
      createParagraph(
        `Режим дозирования препарата ${calculation.name || '-пусто-'} у ${
          calculation.indication || '-'
        } по инструкции (данные, использованны для расчетов) составляет: `,
        {},
        {},
        20
      ),
      createEmptyLine(),
      ...calcOffer,
      createParagraph(calcYear.join(''), {}, {}, 20),
      createParagraph(calcPrice.join(''), {}, {}, 20),
      createEmptyLine(),
      createParagraph(
        `Расчет стоимости 1 года терапии одного пациента: ${
          calculation.combinePrice || ''
        }`,
        {},
        {},
        20
      ),
      ...calcCombine
    )
    tablesVzn.push(createEmptyLine())
  })
  try {
    return [...tablesGnvlp, ...tablesVzn]
  } catch (err) {
    throw new DocxException(1, err.message)
  }
}
