import { AlignmentType, Table, TableCell, TableRow, WidthType } from 'docx'

import {
  createBoldParagraph,
  DEFAULT_TABLE_MARGINS,
  createTableCell,
  BOLD_TEXT,
} from '../docxFunctions'
import {
  createCombinationParagraph,
  DEFAULT_TEXT,
  UNDERLINED_TEXT,
} from '@/components/report/docxFunctions'

const params = new Map([
  ['qLevels', 'Метод. качество'],
  ['condition', 'Показание'],
  ['epidemiology', 'Численность целевой популяции'],
  ['currentState', 'Текущая практика терапии'],
  ['expectedState', 'Ожидаемая практика терапия'],
  ['results', 'Основные результаты'],
])

export function getForAvbScenario(items = [], avbIdx) {
  const outArr = []

  items?.forEach((item, idx, arr) => {
    // если есть сценарии

    if (arr.length > 1) {
      outArr.push(
        createCombinationParagraph([
          {
            type: BOLD_TEXT,
            content: `${idx === 0 ? `АВБ-${avbIdx + 1}: ` : ''}`,
          },
          {
            type: UNDERLINED_TEXT,
            content: `Сценарий ${idx + 1}:`,
          },
        ])
      )
    }

    outArr.push(
      createCombinationParagraph([
        {
          type: BOLD_TEXT,
          content: `${
            idx === 0 && arr.length === 1 ? `АВБ-${avbIdx + 1}:` : ''
          }`,
        },
        {
          type: DEFAULT_TEXT,
          content: ` ${item || ''}`,
        },
      ])
    )
  })
  return outArr
}

function createCellsInRow(tableData, name) {
  const out = []

  if (name === 'qLevels') {
    out.push(
      createTableCell({
        children: [
          createBoldParagraph(
            params.get(name),
            { alignment: AlignmentType.START },
            22
          ),
        ],
        columnSpan: 1,
      })
    )
  }
  tableData.forEach((item, idx) => {
    if (Array.isArray(item[name])) {
      if (name === 'qLevels') {
        out.push(
          createTableCell({
            children: [...getForAvbScenario(item[name], idx)],
            columnSpan: idx === 0 ? 1 : 2,
          })
        )
      } else {
        out.push(
          createTableCell({
            children: [...getForAvbScenario(item[name], idx)],
            columnSpan: 2,
          })
        )
      }
    } else {
      if (name === 'qLevels') {
        out.push(
          createTableCell({
            children: [
              createCombinationParagraph([
                {
                  type: BOLD_TEXT,
                  content: `АВБ-${idx + 1}:`,
                },
                {
                  type: DEFAULT_TEXT,
                  content: item[name],
                },
              ]),
            ],
            columnSpan: idx === 0 ? 1 : 2,
          })
        )
      } else {
        out.push(
          createTableCell({
            children: [
              createCombinationParagraph([
                {
                  type: BOLD_TEXT,
                  content: `АВБ-${idx + 1}:`,
                },
                {
                  type: DEFAULT_TEXT,
                  content: item[name],
                },
              ]),
            ],
            columnSpan: 2,
          })
        )
      }
    }
  })

  return out
}

function createStateCellsInRow(tableData) {
  const out = [
    // createTableCell({
    //   children: [
    //     createBoldParagraph(
    //       params.get(name),
    //       { alignment: AlignmentType.START },
    //       22
    //     ),
    //   ],
    // }),
  ]

  tableData.forEach((item, idx) => {
    out.push(
      createTableCell({
        children: [...getForAvbScenario(item['currentState'], idx)],
      })
    )
  })
  tableData.forEach((item, idx) => {
    out.push(
      createTableCell({
        children: [...getForAvbScenario(item['expectedState'], idx)],
      })
    )
  })
  // if (Array.isArray(item[name])) {
  //   out.push(
  //     createTableCell({
  //       children: [...getForAvbScenario(item[name], idx)],
  //     })
  //   )
  // } else {
  //   out.push(
  //     createTableCell({
  //       children: [
  //         createCombinationParagraph([
  //           {
  //             type: BOLD_TEXT,
  //             content: `АВБ ${idx + 1}: `,
  //           },
  //           {
  //             type: DEFAULT_TEXT,
  //             content: item[name],
  //           },
  //         ]),
  //       ],
  //     })
  //   )
  // }
  // })

  return out
}

function createHeaderRow(headerName, dataLength) {
  return new TableRow({
    children: [
      new TableCell({
        children: [
          createBoldParagraph(
            headerName,
            { alignment: AlignmentType.CENTER },
            22
          ),
        ],
        columnSpan: dataLength * 2,
      }),
    ],
  })
}
function createStateRow(dataLength) {
  return new TableRow({
    children: [
      new TableCell({
        children: [
          createBoldParagraph(
            'Текущая практика терапии',
            { alignment: AlignmentType.CENTER },
            22
          ),
        ],
        columnSpan: dataLength,
      }),
      new TableCell({
        children: [
          createBoldParagraph(
            'Ожидаемая практика терапии',
            { alignment: AlignmentType.CENTER },
            22
          ),
        ],
        columnSpan: dataLength,
      }),
    ],
  })
}
////////////////////////////////////////////
export function createAvbTable(tableData) {
  const rows = []
  // for (let param of params.keys()) {
  //   rows.push(
  //     new TableRow({
  //       children: [...createCellsInRow(tableData, param)],
  //     })
  //   )
  // }
  rows.push(
    new TableRow({
      children: [...createCellsInRow(tableData, 'qLevels')],
    }),
    createHeaderRow('Показание', tableData.length),
    new TableRow({
      children: [...createCellsInRow(tableData, 'condition')],
    }),
    createHeaderRow('Численность целевой популяции:', tableData.length),
    new TableRow({
      children: [...createCellsInRow(tableData, 'epidemiology')],
    }),
    createStateRow(tableData.length),
    new TableRow({
      children: [...createStateCellsInRow(tableData)],
    }),
    createHeaderRow('Основные результаты:', tableData.length),
    new TableRow({
      children: [...createCellsInRow(tableData, 'results')],
    })
    // new TableRow({
    //   children: [...createCellsInRow(tableData, 'analogsCompare')],
    // }),
    // createHeaderRow('Клинические преймущества', tableData.length),
    // new TableRow({
    //   children: [...createCellsInRow(tableData, 'clinicalAdvantages')],
    // }),
    // createHeaderRow('Экономические преимущества', tableData.length),
    // new TableRow({
    //   children: [...createCellsInRow(tableData, 'econAdvantages')],
    // }),
    // createHeaderRow('Основные результаты', tableData.length),
    // new TableRow({
    //   children: [...createCellsInRow(tableData, 'results')],
    // })
  )
  try {
    return new Table({
      margins: DEFAULT_TABLE_MARGINS,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [...rows],
    })
  } catch (err) {
    throw new Error(
      `[createAvbTable] Ошибка построения таблицы "Avb": ${err.message}`
    )
  }
}
