<template>
  <div>
    <v-text-field
      class="mt-2"
      v-model="offerLp"
      :outlined="editMode"
      @input="doChange(false)"
      @blur="doBlur"
      label="Предлагаемый ЛП"
      persistent-hint
      dense
    ></v-text-field>
    <!-- <span v-else class="text-subtitle-2 mb-2"
      >Предлагаемый ЛП- {{ mainName || 'отсутствует' }}</span
    > -->
    <v-text-field
      class="mt-2"
      v-model="dosageMedicine"
      :outlined="editMode"
      @input="doChange(false)"
      @blur="doBlur"
      label="например, 100 мг 2 раза в сутки"
      persistent-hint
      dense
    ></v-text-field>

    <v-text-field
      v-model="durationTherapy"
      :outlined="editMode"
      @input="doChange(false)"
      @blur="doBlur"
      label="Продолжительность терапии в год:"
      persistent-hint
      dense
    ></v-text-field>
    <v-text-field
      v-model="costOneMg"
      :outlined="editMode"
      @input="doChange(false)"
      @blur="doBlur"
      :label="`Стоимость 1 мг препарата ${mainName}`"
      persistent-hint
      dense
    ></v-text-field>
    <div v-for="(cost, idx) in costOnePatient" :key="cost.id" class="d-flex">
      <v-text-field
        v-model="cost.formula"
        :outlined="editMode"
        @input="doChange(false)"
        @blur="doBlur"
        persistent-hint
        :label="`Расчет стоимости 1 года терапии одного пациента (${idx + 1}-й
          год)`"
        hint="формула расчетов с результатами"
        class="mb-3"
        dense
      >
      </v-text-field>
      <div
        class="ml-2"
        v-if="
          editMode &&
          idx === costOnePatient.length - 1 &&
          costOnePatient.length > 1
        "
      >
        <btn-with-confirm
          v-if="editMode"
          text
          icon
          icon-main="mdi-delete-forever-outline"
          title="Удалить"
          @click="deleteCalculation"
        >
        </btn-with-confirm>
      </div>
      <v-btn
        v-if="editMode && idx === costOnePatient.length - 1"
        class="ml-2"
        @click="addPatient"
        title="Добавить расчёт стоймости"
        icon
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </div>
    <div v-if="editMode && itemIndex !== 0" class="d-flex">
      <btn-with-confirm
        :disabled="!editMode"
        icon
        icon-main="mdi-delete-forever-outline"
        title="Удалить рассчёт"
        @click="deleteManual"
      />
      <v-spacer />
    </div>
    <v-divider class="mb-2" />
  </div>
</template>

<script>
import { copyObject } from '@/lib/objects'
import btnWithConfirm from '@/components/buttons/btnWithConfirm'

export default {
  name: 'CostManual',
  components: { btnWithConfirm },
  data: () => ({
    calculationCost: null,
    dosageMedicine: '',
    durationTherapy: '',
    costOneMg: '',
    costOnePatient: [],
    offerLp: '',
  }),
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
    mainName: {
      type: String,
      default: '',
    },
    mnn: { type: String },
  },
  created() {
    this.calculationCost = copyObject(this.item) || {}
    this.dosageMedicine = this.calculationCost?.dosageMedicine || ''
    this.durationTherapy = this.calculationCost?.durationTherapy || ''
    this.costOneMg = this.calculationCost?.costOneMg || ''
    this.costOnePatient = this.calculationCost?.costOnePatient || []
    this.offerLp = this.calculationCost?.offerLp || ''
  },
  methods: {
    doChange(force) {
      const {
        dosageMedicine,
        durationTherapy,
        costOneMg,
        costOnePatient,
        offerLp,
      } = this
      this.$emit(
        'change',
        {
          dosageMedicine,
          durationTherapy,
          costOneMg,
          costOnePatient,
          offerLp,
        },
        force
      )
    },
    doBlur() {
      this.$emit('blur')
    },
    deleteManual() {
      this.$emit('delete')
    },
    addPatient() {
      this.costOnePatient.push({ formula: '' })
      this.doChange(true)
    },
    deleteCalculation() {
      this.costOnePatient.pop()
      this.doChange(true)
    },
  },
}
</script>
