<template>
  <div>
    <div class="text-center my-2">
      <span class="font-weight-bold text-caption">{{
        indications.join(', ')
      }}</span>
    </div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.id">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in tableData"
            :class="regex.test(item.groupName.value) ? 'green--text' : ''"
            :key="item.id"
          >
            <CostItem
              v-for="el in Object.keys(item)"
              :formatter="formatterItem(el)"
              :key="el"
              :item="item[el]"
            />
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div
      class="text-caption font-weight-bold"
      v-for="footer in footers"
      :key="footer.id"
    >
      <span>{{ footer }}</span>
    </div>
    <span class="v-card__title">Расчёты</span>
    <v-container>
      <v-row dense>
        <v-col cols="3" class="text-subtitle-2">
          <span>Режим дозирования препарата</span>
        </v-col>
        <v-col cols="9" class="d-flex flex-wrap"
          ><AutoGrowTextEditor
            v-model="name"
            :outlined="editMode"
            @input="doChange(false)"
            @blur="doBlur"
            label="название предлагаемого препарата"
            persistent-hint
            dense
            :rows="1"
          ></AutoGrowTextEditor
        ></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3" class="text-subtitle-2"> в терапии </v-col>
        <v-col cols="9" class="d-flex flex-wrap">
          <AutoGrowTextEditor
            v-model="indication"
            :outlined="editMode"
            @input="doChange(false)"
            @blur="doBlur"
            label="показание (например, у взрослых пациентов с рецидивирующей или рефрактерной множественной миеломой)"
            persistent-hint
            dense
            :rows="1"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3" class="text-subtitle-2">
          по инструкции (использованы в расчетах) составляет
        </v-col>
        <v-col cols="9" class="d-flex flex-wrap">
          <AutoGrowTextEditor
            v-model="calculation"
            :outlined="editMode"
            @input="doChange(false)"
            @blur="doBlur"
            label="например 1800 мг 1 раз в неделю с 1-ой по 8-ю неделю, 1 раз в 2 недели с 9-ой по 24-ю недели, 1 раз в 4 недели с 25-ой недели до прогрессирования"
            persistent-hint
            dense
            :rows="1"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-switch v-model="isCombine" @change="doChange(true)" @blur="doBlur()">
      <template #label>
        Используется ли комбинация препаратов?
        <span class="font-weight-bold ml-3">
          {{ isCombine ? 'Да' : 'Нет' }}</span
        ></template
      >
    </v-switch>
    <v-tabs v-model="activeTab">
      <v-tab
        v-for="(item, tabIndex) in calculationCost"
        :key="tabIndex"
        class="font-weight-bold"
        ><span> рассчеты {{ tabIndex + 1 }} </span>
      </v-tab>
      <v-btn class="mt-2" small @click="addManual" v-if="editMode" icon
        ><v-icon> mdi-plus </v-icon></v-btn
      >

      <v-tab-item
        v-for="(item, itemIndex) in calculationCost"
        :key="itemIndex"
        class="pa-2"
        eager
      >
        <CostManual
          v-if="calculationCost[itemIndex]"
          :item="item"
          :editMode="editMode"
          :itemIndex="itemIndex"
          :mainName="name"
          :mnn="mnn"
          @change="
            (data, force) => {
              doChangeCalculation(data, itemIndex, force)
            }
          "
          @blur="doBlur()"
          @delete="deleteManual(itemIndex)"
        />
        <v-alert v-else type="error" outlined
          >Информация для рассчета {{ itemIndex + 1 }} отсутствует</v-alert
        >
      </v-tab-item>
    </v-tabs>
    <v-text-field
      v-if="isCombine"
      v-model="combinePrice"
      :outlined="editMode"
      @input="doChange(false)"
      @blur="doBlur"
      persistent-hint
      label="Стоймость комбинаций"
      class="mb-3"
      dense
    >
    </v-text-field>
  </div>
</template>

<script>
import { copyObject } from '@/lib/objects'
import CostItem from './CostItem'
import { toMoney } from '@/lib/strUtils'
import CostManual from './CostManual.vue'
import { DEFAULT_CALCULATION_COST } from '../const'
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor'

const regex = new RegExp(`^\\s*Предлагаемый*\\s*ЛП*`, 'gm')

export default {
  name: 'CostTable',
  components: { CostItem, CostManual, AutoGrowTextEditor },
  data: () => ({
    indications: [],
    footers: [],
    tableData: [],
    headers: [],
    calculationCost: [],
    regex,
    activeTab: 0,
    name: '',
    indication: '',
    calculation: '',
    isCombine: false,
    combinePrice: '',
  }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    calculations: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    mnn: { type: String },
    condition: { type: String },
  },
  watch: {
    value: 'init',
  },
  methods: {
    init() {
      this.tableData = copyObject(this.value?.tableData) || []
      this.indications = this.value?.valuesIndications || []
      this.footers = this.value?.valuesFooters || []
      this.headers = this.value?.headers || []

      this.calculationCost = this.calculations?.costs || []
      this.name = this.calculations?.name || ''
      this.indication = this.calculations?.indication || ''
      this.calculation = this.calculations?.calculation || ''
      this.isCombine = this.calculations?.isCombine || false
      this.combinePrice = this.calculations?.combinePrice || ''
    },
    doChangeCalculation(data, itemIndex, force = false) {
      this.calculationCost[itemIndex] = data
      this.doChange(force)
    },
    doChange(force = false) {
      const { name, indication, isCombine, combinePrice, calculation } = this
      this.$emit(
        'change',
        {
          name,
          indication,
          calculation,
          isCombine,
          combinePrice,
          costs: this.calculationCost,
        },
        force
      )
    },
    doBlur() {
      this.$emit('blur')
    },
    formatterItem(key) {
      if (key === 'patientPopulation')
        return function (value) {
          return Number(value) ? Number(value).toLocaleString('ru') : value
        }
      if (key === 'requiredEiNumber')
        return function (value) {
          return Number(value)
            ? Number(value.toFixed(2)).toLocaleString('ru')
            : value
        }
      if (key === 'priceEiNumber' || key === 'priceTherapy') {
        return function (value) {
          return toMoney(value, true)
        }
      }
    },
    addManual() {
      this.calculationCost.push(DEFAULT_CALCULATION_COST())
      this.activeTab = this.calculationCost?.length - 1 || 0
      this.doChange(this.calculationCost, true)
    },
    deleteManual(idx) {
      this.calculationCost.splice(idx, 1)
      this.doChange(this.calculationCost, true)
    },
    //Рекурсивно проверяем на присутствие значения в следующей строке, если значения нет, то добавляем idx объединения
    // getIdx(tableData, i, key, count = 1) {
    //   if (
    //     tableData[i]?.[`${key}`]?.value &&
    //     tableData[i + count] &&
    //     !tableData[i + count]?.[`${key}`]?.value
    //   ) {
    //     return this.getIdx(tableData, i, key, count + 1)
    //   } else return count
    // },
  },
  created() {
    this.init()
  },
}
</script>
